<template>
  <div class="common-bg">
    <van-nav-bar title="股权证书" left-arrow @click-left="onClickLeft" />
    <div v-if="list.length > 0">
      <div
        v-for="item in list"
        :key="item.id"
        class="list"
        @click="toDetails(item)"
      >
        <div class="stock-card">
          <div class="card-li">
            <span class="text-grey">股权名称：</span>
            <span class="font-weight">{{ item.productTitle }}</span>
          </div>
          <div class="card-li">
            <span class="text-grey">购买数量：</span>
            <span class="font-weight">{{ item.num }}股</span>
          </div>
          <div class="card-li">
            <span class="text-grey">购买金额：</span>
            <span class="font-weight">{{ item.money }}元</span>
          </div>
          <!-- <div class="card-li">
              <span class="text-grey">每日分红：</span>
              <span class="font-weight text-warning">{{item.fenhong}}元</span>
            </div> -->
          <div class="card-li">
            <span class="text-grey">购买时间：</span>
            <span class="font-weight">{{ item.createTime }}</span>
          </div>
        </div>
       <div class="img-box">
          <van-image
            width="100"
            height="100"
            fit="cover"
            position="top"
            :src="require('../../assets/images/my/stock.png')"
          />
        </div> 
      </div>
    </div>
    <div class="text-center padded-t-15" v-else>暂无记录</div>
  </div>
</template>

<script>
import { getUserProductList } from '@/utils/api.js'
export default {
  name: 'stockDetail',
  data() {
    return {
      list: []
    }
  },
  created() {
    this.initData()
  },
  methods: {
    initData() {
      getUserProductList(1).then((res) => {
        this.list = res.data
      })
    },
    onClickLeft() {
      this.$router.go(-1)
    },
    toDetails(item) {
      console.log(item)
      console.log(item.productTitle)
      this.$router.push('/user/product')
      localStorage.setItem('product', JSON.stringify(item))
    }
  }
}
</script>

<style lang="scss" scoped>
.list {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: hsla(0, 0%, 100%, 0.7);
  border-radius: 8px;
  padding: 15px;
  margin: 10px;
}
.stock-card {
  font-size: 14px;
  .card-li {
    padding: 3px 0;
  }
}
</style>
